<template>
    <b-sidebar
        id="add-new-shop-sidebar"
        :visible="isAddNewShopSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="val => $emit('update:is-add-new-shop-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Add New User</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <!-- Shop Name -->
                    <validation-provider #default="validationContext" name="Shop Name" rules="required">
                        <b-form-group label="Shop Name" label-for="shop-name">
                            <b-form-input
                                id="shop-name"
                                v-model="shopData.name"
                                autofocus
                                :state="getValidationState(validationContext)"
                                trim
                                placeholder="Nike Air Store"
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- URL -->
                    <validation-provider #default="validationContext" name="URL" rules="required|url">
                        <b-form-group label="URL" label-for="url">
                            <b-form-input
                                id="url"
                                v-model="shopData.url"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- API KEY -->
                    <validation-provider #default="validationContext" name="Api Key" rules="required">
                        <b-form-group label="Api Key" label-for="api-key">
                            <b-form-input
                                id="api-key"
                                v-model="shopData.api_key"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- API SECRET -->
                    <validation-provider #default="validationContext" name="Api Secret" rules="required">
                        <b-form-group label="Api Secret" label-for="api-secret">
                            <b-form-input
                                id="api-key"
                                v-model="shopData.api_secret"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Shop Type -->
                    <validation-provider #default="validationContext" name="Type" rules="required">
                        <b-form-group label="Type" label-for="type" :state="getValidationState(validationContext)">
                            <v-select
                                v-model="shopData.type"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="shopOptions"
                                :reduce="val => val.value"
                                :clearable="false"
                                input-id="type"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- LOGO URL -->
                    <validation-provider #default="validationContext" name="Logo" rules="required|url">
                        <b-form-group label="Logo" label-for="logo">
                            <b-form-input
                                id="logo"
                                v-model="shopData.logo_url"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            Add
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cancel
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        vSelect,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isAddNewShopSidebarActive",
        event: "update:is-add-new-shop-sidebar-active",
    },
    props: {
        isAddNewShopSidebarActive: {
            type: Boolean,
            required: true,
        },
        roleOptions: {
            type: Array,
            required: true,
        },
        shopOptions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            required,
            alphaNum,
            email,
        };
    },
    setup(props, { emit }) {
        const blankShopData = {
            name: "",
            url: "",
            type: null,
            api_key: "",
            api_secret: "",
            logo_url: "",
        };

        const shopData = ref(JSON.parse(JSON.stringify(blankShopData)));
        const resetshopData = () => {
            shopData.value = JSON.parse(JSON.stringify(blankShopData));
        };

        const onSubmit = () => {
            store.dispatch("admin-shop/addShop", shopData.value).then(() => {
                emit("refetch-data");
                emit("update:is-add-new-shop-sidebar-active", false);
            });
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetshopData);

        return {
            shopData,
            onSubmit,

            refFormObserver,
            getValidationState,
            resetForm,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-shop-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
